import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setActiveUser } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";

export default function SignIn() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("fake@example.com");
  const [password, setPassword] = useState("password1");
  useEffect(() => {
    if (user) {
      history("/home");
    } else if (localStorage.getItem("token")) {
      getUser();
    }
  });

  const getUser = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/get-user`, config)
      .then((res2) => {
        console.log(res2);
        dispatch(setActiveUser(res2.data.data));
        window.location.href = "/home";
      })
      .catch((err) => {});
  };

  const startAuth = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "/auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.tokens.access.token);
        getUser();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="page_title section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="page_title-content">
                <p>Get started tracking your social profiles</p>
                <h3>Register</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="ani-6">
          <img src="/assets/circle2.svg" alt="" />
        </div>
        <div className="ani-7">
          <img src="/assets/circleline.svg" alt="" />
        </div>
        <div className="ani-8">
          <img src="/assets/polygon.svg" alt="" />
        </div>
      </div>
      <div className="affiliates">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-7">
              <div className="refferal_link">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Register Here</h4>
                    <p>
                      Welcome. Please create an account to start tracking your
                      social accountss.
                    </p>
                    <div className="referral_form">
                      <form action="#">
                        <div className="form-row align-items-center">
                          <div className="form-group col-xl-12">
                            <label htmlFor="">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-xl-12">
                            <label htmlFor="">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-xl-12">
                            <label htmlFor="">Password</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="col-xl-12">
                            <div className="row jc-sb">
                              <div className="form-social  col-xl-4">
                                {!loading && (
                                  <button
                                    className="btn btn-primary"
                                    onClick={startAuth}
                                  >
                                    Register
                                  </button>
                                )}
                              </div>
                              <div className="form-social col-xl-4">
                                <a href="/login" className="secondary-a">
                                  Login
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
