import * as Constants from "../constants";

export function setActiveUser(data) {
  return {
    type: Constants.SET_ACTIVE_USER,
    payload: data,
  };
}
export function setCurrentModal(modal, client, layout, data, network) {
  console.log(modal, client, layout, data, network);
  return {
    type: Constants.SET_ACTIVE_MODAL,
    payload: modal,
    client,
    layout,
    data,
    network,
  };
}
export function setCurrentSideNav(data, client) {
  return {
    type: Constants.SET_ACTIVE_SIDE_NAV,
    payload: data,
    client,
  };
}
export function setLayout(data, client) {
  return {
    type: Constants.SET_ACTIVE_LAYOUT,
    payload: data,
  };
}
