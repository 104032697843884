const httpRequest = require("request");
const token = localStorage.getItem("token");

module.exports = {
	makeUrlParams: (myData) => {
		var out = [];

		for (var key in myData) {
			out.push(key + "=" + encodeURIComponent(myData[key]));
		}

		const params = out.join("&");
		return params;
	},
	startAuth: function (platform) {
		localStorage.setItem("selectedNetwork", platform);
		module.exports.get(`${platform}/start-auth`).then((value) => {
			const res = JSON.parse(value.body);
			if (res.redirectUrl) {
				if (platform === "twitter") {
					localStorage.setItem(
						"oauthRequestTokenSecret",
						res.oauthRequestTokenSecret
					);
					localStorage.setItem(
						"oauthRequestToken",
						res.oauthRequestToken
					);
				}
				window.location.href = res.redirectUrl;
			}
		});
	},
	get: function (path, passedToken) {
		if (passedToken === undefined) {
			passedToken = token;
		}
		const options = {
			url: `${process.env.REACT_APP_API_URL}/${path}`,
			method: "GET",
			gzip: true,
			headers: {
				Authorization: `Bearer ${passedToken}`,
				"Content-Type": "application/json",
			},
		};
		return new Promise((resolve, reject) => {
			httpRequest(options, (error, response, body) => {
				if (response) {
					console.log(response);
					return resolve(response);
				}
				if (error) {
					return reject(error);
				}
			});
		});
	},
	post: function (path, data) {
		let passedToken;
		if (data.passedToken === undefined) {
			passedToken = token;
		} else {
			passedToken = data.passedToken;
		}
		const options = {
			url: `${process.env.REACT_APP_API_URL}/${path}`,
			method: "POST",
			body: data,
			json: true,
			headers: {
				Authorization: `Bearer ${passedToken}`,
				"Content-Type": "application/json",
			},
		};
		return new Promise((resolve, reject) => {
			httpRequest(options, (error, response, body) => {
				if (response) {
					return resolve(response);
				}
				if (error) {
					return reject(error);
				}
			});
		});
	},
	makeTitle: function (slug, hideNetwork = false, network = null) {
		if (slug && slug.length) {
			if (hideNetwork) {
				slug = slug.replace(`${network}_`, "");
			}
			var words = slug.split("_");

			for (var i = 0; i < words.length; i++) {
				var word = words[i];
				words[i] = word.charAt(0).toUpperCase() + word.slice(1);
			}

			return words.join(" ").replace("Profile ", "");
		}
	},
	getNetworkFromString: function (str, strArray) {
		for (var j = 0; j < strArray.length; j++) {
			if (str.includes(strArray[j])) return strArray[j];
		}
		return -1;
	},
	getNetworks: function () {
		return [
			"twitter",
			"instagram",
			"tiktok",
			"youtube",
			"facebook",
			"linkedin",
		];
	},
	getPostCardStats: function (name, media) {
		let stats;
		if (
			name === "instagram_profile_top" ||
			name === "instagram_profile_bottom"
		) {
			stats = [
				{ title: "Engagement", value: media.engagement },
				{
					title: "Reach Engagement Rate",
					value: `${
						media.engagement > 0 && media.reach > 0
							? Math.round(media.reach / media.engagement)
							: 0
					}%`,
				},
				{ title: "Comments", value: media.comments_count },
			];
		} else if (
			name === "twitter_profile_top" ||
			name === "twitter_profile_bottom"
		) {
			stats = [
				{ title: "Engagement", value: media.engagement },
				{
					title: "Reach Engagement Rate",
					value: `${
						media.engagement > 0 && media.reach > 0
							? Math.round(media.reach / media.engagement)
							: 0
					}%`,
				},
				{ title: "Comments", value: media.comments_count },
			];
		} else if (
			name === "youtube_profile_top" ||
			name === "youtube_profile_bottom"
		) {
			stats = [
				{ title: "Views", value: media.views },
				{
					title: "Reach Engagement Rate",
					value: `${
						media.engagement > 0 && media.reach > 0
							? Math.round(media.reach / media.engagement)
							: 0
					}%`,
				},
				{ title: "Comments", value: media.comments_count },
			];
		}
		return stats;
	},
};
