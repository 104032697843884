import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCurrentModal } from "../../redux/action-creators";

const EmptyDashboard = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const fields = useSelector((state) => state);

    const showWidgetOptions = () =>
        dispatch(
            setCurrentModal(
                "WidgetMenu",
                Object.keys(params).length !== 0 ? params.id : fields.user.id,
                null,
                null,
                props.network,
            ),
        );

    return (
        <div className="popup_content claim pt-5 text-center pb-20">
            <h4>No Dashboard</h4>
            <p>
                It looks like you have not created a dashboard. Let's start
                choosing widgets for your social accounts!
            </p>

            <button
                type="button"
                className="btn btn-primary mt-5"
                data-bs-toggle="modal"
                data-bs-target="#popup_claim2"
                onClick={showWidgetOptions}
            >
                Create Dashboard Now
            </button>
        </div>
    );
};
export default EmptyDashboard;
