import React, { useState, useEffect, useCallback } from "react";
import { post, get } from "../helpers/functions";
import { setActiveUser } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";

export default function SignIn() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token = localStorage.getItem("token");
  const getUser = useCallback(async () => {
    const userResult = await get(`auth/get-user`, token);
    if (userResult) {
      dispatch(setActiveUser(userResult.body.data));
    }
  }, [dispatch, token]);

  const startAuth = useCallback(async () => {
    setLoading(true);
    setError(false);
    post("auth/login", {
      email,
      password,
    })
      .then((authData) => {
        localStorage.setItem("token", authData.body.tokens.access.token);
        setTimeout(() => {
          window.location.href = "/home";
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        setError("Incorrect Login Info");
      });
  }, [email, password]);

  const onEmailChange = (e) => {
    console.log(email);
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    console.log(e.target.value);
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (user) {
      window.location.href = "/home";
    } else if (token) {
      getUser();
    }
  }, [getUser, user, token]);

  return (
    <div className="container-center-horizontal">
      <div className="login screen">
        <div className="frame-1547764388">
          <div className="login-form">
            <div className="header-1 header-2">
              <h1 className="title">Login</h1>
              <p className="get-started-tracking">
                Get started tracking your social profiles.
              </p>
            </div>
            <div className="section section-1">
              <div className="section-item section-1">
                <div className="email">Email</div>
                <input
                  type="text"
                  className="form-control form form-2"
                  placeholder="Email"
                  value={email}
                  onChange={onEmailChange}
                />
              </div>
              <div className="section-item section-1">
                <div className="password">Password</div>
                <input
                  type="password"
                  className="form-control form form-2"
                  placeholder="Password"
                  value={password}
                  onChange={onPasswordChange}
                />
              </div>
            </div>
            <button onClick={startAuth} className="button">
              {!loading && (
                <div className=" log-in label-2" onClick={startAuth}>
                  Login
                </div>
              )}
            </button>
            {error && <p className="error-text">{error}</p>}
          </div>
          <p className="dont-have-an-account-sign-up satoshi-regular-normal-fuscous-gray-14px">
            <span className="span0 satoshi-regular-normal-fuscous-gray-14px">
              Don’t have an account?{" "}
            </span>
            <span className="span1 satoshi-regular-normal-fuscous-gray-14px">
              Sign up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
