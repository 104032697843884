import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { post } from "../../helpers/functions";
import { setCurrentModal } from "../../redux/action-creators";

export default function LinkedinSelect(props) {
	const dispatch = useDispatch();
	const fields = useSelector((state) => state);

	const selectAccount = (account) => {
		post("linkedin/passAccount", {
			account: account,
			client: fields.incomingData.client,
		}).then((res) => {
			closeModal();
			if (fields.user.role === "admin") {
				const clientID = fields.incomingData.client;
				window.location.href = "/dashboard/" + clientID + "?setup=true";
			} else {
				window.location.href = "/home";
			}
		});
	};

	const closeModal = () => {
		dispatch(setCurrentModal(null, null, null, null));
	};
	return (
		<div className="modal widget-menu ">
			<button className="close-btn" onClick={closeModal}>
				<i className="las la-times-circle"></i>
			</button>
			<div className="modal-container">
				<div className="social_task">
					<div className="social_task-header">
						<div className="title">
							<p>Select Account</p>
						</div>
					</div>
					<div className="social_task-table table-responsive table">
						<table className="table" style={{ maxWidth: "600px" }}>
							<tbody>
								{fields.incomingData.accounts &&
									fields.incomingData.accounts.map(
										(account) => (
											<tr>
												<td style={{ width: "100px" }}>
													<span className="social_icon twitter">
														<img
															src="/assets/linkedin.svg"
															alt=""
														/>
													</span>
												</td>

												<td>
													<p>
														<strong>
															{
																account.localizedName
															}
														</strong>
													</p>
												</td>
												<td className="text-center">
													<button
														onClick={() =>
															selectAccount(
																account
															)
														}
														className="btn btn-primary"
													>
														Select
													</button>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
