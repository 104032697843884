import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartsSample = (props) => {
	let data = [];
	if (props.data && props.data.length > 0) {
		data = props.data;
	}
	// if (props.before) {
	// 	data = props.data.filter((item) => {
	// 		var before = props.before;
	// 		var after = props.after;
	// 		var beforeInMoment = moment.utc(before, "MM/YYYY");
	// 		var afterInMoment = moment.utc(after, "MM/YYYY");
	// 		var currentInMoment = moment.utc(item.date, "MM/YYYY");
	// 		return (
	// 			beforeInMoment.isBefore(currentInMoment) &&
	// 			afterInMoment.isAfter(currentInMoment)
	// 		);
	// 	});
	// }
	let color;
	if (props.network === "facebook") {
		color = "#3b5998";
	} else if (props.network === "instagram") {
		color = "#c32aa3";
	} else if (props.network === "youtube") {
		color = "#ff0000";
	} else if (props.network === "twitter") {
		color = "#1da1f2";
	} else if (props.network === "tiktok") {
		color = "#010101";
	} else if (props.network === "linkedin") {
		color = "#287bbc";
	}

	const state = {
		series: [
			{
				name: props.title,
				data: data.map((item) => item.value),
				labels: {
					formatter: function (value) {
						return props.network === "linkedin" &&
							props.title === "Linkedin Engagement"
							? value + "%"
							: value;
					},
				},
			},
		],
		options: {
			chart: {
				type: "bar",
				height: 350,
				stacked: true,
				toolbar: {
					show: true,
				},
				zoom: {
					enabled: true,
				},
				events: {
					zoomed: function (chartContext: any, { xaxis, yaxis }) {
						console.log("xAxis", xaxis, yaxis);
					},
				},
			},

			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: "bottom",
							offsetX: -10,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					dataLabels: {
						total: {
							enabled: true,
							style: {
								fontSize: "13px",
								fontWeight: 900,
							},
						},
					},
				},
			},
			xaxis: {
				categories: data.map((item) => item.date),
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return props.network === "linkedin" &&
							props.title === "Linkedin Engagement"
							? value + "%"
							: value;
					},
				},
			},
			fill: {
				opacity: 1,
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, opt) {
					return props.network === "linkedin" &&
						props.title === "Linkedin Engagement"
						? value + "%"
						: value;
				},
			},

			colors: [color],
		},
	};
	return (
		<ReactApexChart
			options={state.options}
			series={state.series}
			type="bar"
			height={340}
		/>
	);
};

export default ApexChartsSample;
