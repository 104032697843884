import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import PostCard from "./elements/PostCard";
import {
  getNetworkFromString,
  getNetworks,
  makeTitle,
} from "../../helpers/functions";

const PostsWidget = (props) => {
  const [showOptions, setShowOptions] = useState(false);

  const network = getNetworkFromString(props.name, getNetworks());
  let filterOptions;
  if (network === "twitter") {
    filterOptions = [
      { value: "like_counts", label: "Like Count" },
      { value: "retweet_count", label: "Retweets" },
      { value: "reply_count", label: "Replies" },
      { value: "impression_count", label: "Impressions" },
      { value: "saved", label: "Saves" },
    ];
  } else if (network === "instagram") {
    filterOptions = [
      { value: "interactions", label: "Interactions" },
      { value: "reach", label: "Reach" },
      { value: "impressions", label: "Impressions" },
      { value: "likes", label: "Likes" },
      { value: "saved", label: "Saves" },
    ];
  } else if (network === "facebook") {
    filterOptions = [
      { value: "interactions", label: "Interactions" },
      { value: "reach", label: "Reach" },
      { value: "impressions", label: "Impressions" },
      { value: "likes", label: "Likes" },
    ];
  } else if (network === "youtube") {
    filterOptions = [
      { value: "views", label: "Views" },
      { value: "likes", label: "Likes" },
      { value: "dislikes", label: "Dislikes" },
      { value: "shares", label: "Shares" },
    ];
  } else if (network === "linkedin") {
    filterOptions = [
      { value: "likes", label: "Likes" },
      { value: "comments_count", label: "Comments" },
    ];
  } else if (network === "tiktok") {
    filterOptions = [
      { value: "likes", label: makeTitle("likes") },
      { value: "views", label: makeTitle("views") },
      {
        value: "average_time_watched",
        label: makeTitle("average_time_watched"),
      },
      { value: "reach", label: makeTitle("reach") },
      {
        value: "total_time_watched",
        label: makeTitle("total_time_watched"),
      },
      {
        value: "engagement",
        label: makeTitle("engagement"),
      },
    ];
  }
  console.log(filterOptions);
  return (
    <div className="card h-100 mb-xl-5 gap-4 widget-desktop-list-with-highlight-md ">
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {makeTitle(network)}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {makeTitle(props.name, true, network)}
          </span>
        </h3>

        <div className="card-toolbar">
          <div class="end" onClick={() => setShowOptions(!showOptions)}>
            <img
              class="dots-three"
              src="/assets/img/dotsthree.svg"
              alt="DotsThree"
            />
          </div>
          <div
            className={`top-57 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
              showOptions ? "active" : ""
            }`}
            data-kt-menu="true"
          >
            <div className="menu-item px-5 py-4">
              <div
                className="d-widgetOptionsFolded__button"
                onClick={() => props.removeWidget(props.name)}
                name={props.name}
                data-tooltip-content="Close"
                id={`close-${props.name}`}
              >
                Remove
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body d-flex flex-column">
        <div className="wrapper-5 wrapper-6">
          {props.data &&
            props.data.length > 0 &&
            props.data.map((media) => (
              <PostCard
                network={network}
                media={media}
                name={props.name}
                filter={props.value}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PostsWidget;
