import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import "react-tooltip/dist/react-tooltip.css";
import {
  getNetworkFromString,
  getNetworks,
  makeTitle,
} from "../../helpers/functions";

const SmallWidget = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const network = getNetworkFromString(props.name, getNetworks());
  const title = makeTitle(props.name, true, network);
  const n = title.split(" ");
  const label = n[n.length - 1];
  return (
    <div className="card h-100 mb-xl-6 gap-4 widget-desktop-list-with-highlight-md ">
      <div className="card-header border-0 py-5">
        <div className="wrapper">
          <div className="primary-text display">
            <NumericFormat
              value={props.data}
              thousandSeparator=","
              displayType={"text"}
            />
          </div>

          <div className="card-toolbar">
            <div className="end" onClick={() => setShowOptions(!showOptions)}>
              <img
                className="dots-three"
                src="/assets/img/dotsthree.svg"
                alt="DotsThree"
              />
            </div>
            <div
              className={`top-57 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                showOptions ? "active" : ""
              }`}
              data-kt-menu="true"
            >
              <div className="menu-item px-5 py-4">
                <div
                  className="d-widgetOptionsFolded__button"
                  onClick={() => props.removeWidget(props.name)}
                  name={props.name}
                  data-tooltip-content="Close"
                  id={`close-${props.name}`}
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-text label-1">Total {label}</div>
      </div>
    </div>
  );
};

export default SmallWidget;
