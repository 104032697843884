import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
/* import Perf from 'react-addons-perf'; */
import App from "./App";
import "./css/metronix.css";
import "./css/grid.css";
import "./css/fonts.css";
import "./css/globals.css";
import "./css/styleguide.css";
import "./css/landing.css";
import "./css/add-widget.css";
import "./css/content-detail.css";
import "./css/dashboard-landing-tab.css";
import "./css/radar-home.css";
import "./css/widget-detail.css";
import "./css/login.css";
import "./css/header.css";
import "./css/entry.css";

/* 
window.Perf = Perf;
Perf.start();
*/

const renderApp = () => {
  render(
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>,
    document.getElementById("kt_app_page"),
  );
};
renderApp();

if (module.hot) {
  module.hot.accept("./App", () => {
    renderApp();
  });
}
