import React from "react";
import Moment from "react-moment";
import { Tweet } from "react-twitter-widgets";
import { LinkedInEmbed } from "react-social-media-embed";
import { makeTitle } from "../../../helpers/functions";

const PostCard = (props) => {
  const { media } = props;
  //console.log(media);
  let stats = [];
  let statsToDisplay = [];
  if (props.network === "instagram") {
    statsToDisplay = [
      "reach",
      "impressions",
      "likes",
      "saved",
      "engagement",
      "reach_engagement_rate",
    ];
  } else if (props.network === "facebook") {
    statsToDisplay = [
      "reach",
      "impressions",
      "likes",
      "saved",
      "engagement",
      "reach_engagement_rate",
    ];
  } else if (props.network === "tiktok") {
    statsToDisplay = [
      "likes",
      "views",
      "average_time_watched",
      "full_video_watched_rate",
      "reach",
      "engagement",
    ];
  } else if (props.network === "linkedin") {
    statsToDisplay = ["likes", "comments_count"];
  }
  if (
    props.name === "instagram_profile_top" ||
    props.name === "instagram_profile_bottom"
  ) {
    stats = statsToDisplay.map((item) => {
      if (item === "reach_engagement_rate") {
        return {
          title: "Reach Engagement Rate",
          value: `${
            media.engagement > 0 && media.reach > 0
              ? Math.round(media.reach / media.engagement)
              : 0
          }%`,
        };
      }
      return { title: makeTitle(item), value: media[item] };
    });
  } else if (
    props.name === "facebook_profile_top" ||
    props.name === "facebook_profile_bottom"
  ) {
    stats = statsToDisplay.map((item) => {
      if (item === "reach_engagement_rate") {
        return {
          title: "Reach Engagement Rate",
          value: `${
            media.engagement > 0 && media.reach > 0
              ? Math.round(media.reach / media.engagement)
              : 0
          }%`,
        };
      }
      return { title: makeTitle(item), value: media[item] };
    });
  } else if (
    props.name === "twitter_profile_top" ||
    props.name === "twitter_profile_bottom"
  ) {
    stats = [
      { title: "Engagement", value: media.engagement },
      {
        title: "Reach Engagement Rate",
        value: `${
          media.engagement > 0 && media.reach > 0
            ? Math.round(media.reach / media.engagement)
            : 0
        }%`,
      },
      { title: "Comments", value: media.comments_count },
    ];
  } else if (
    props.name === "youtube_profile_top" ||
    props.name === "youtube_profile_bottom"
  ) {
    stats = [
      { title: "Views", value: media.views },
      {
        title: "Reach Engagement Rate",
        value: `${
          media.engagement > 0 && media.reach > 0
            ? Math.round(media.reach / media.engagement)
            : 0
        }%`,
      },
      { title: "Comments", value: media.comments_count },
    ];
  } else if (
    props.name === "tiktok_profile_top" ||
    props.name === "tiktok_profile_bottom"
  ) {
    const percentageMetrics = [
      "engagement",
      "impression_source_follow",
      "impression_source_for_you",
      "impression_source_hashtag",
      "impression_source_sound",
      "full_video_watched_rate",
    ];

    const secondsWatchedMetrics = [
      "average_time_watched",
      "total_time_watched",
    ];
    stats = statsToDisplay.map((item) => {
      let suffix = "";
      let value = media[item];
      if (percentageMetrics.includes(item)) {
        suffix = "%";
        if (item !== "engagement") {
          value = Math.round(media[item] * 100);
        }
      } else if (secondsWatchedMetrics.includes(item)) {
        suffix = " secs";
      }
      return {
        title: makeTitle(item).replace("Impression Source ", "From "),
        value: `${value ? value : 0}${suffix}`,
      };
    });
  } else if (
    props.name === "linkedin_profile_top" ||
    props.name === "linkedin_profile_bottom"
  ) {
    stats = statsToDisplay.map((item) => {
      return { title: makeTitle(item), value: media[item] };
    });
  }

  let postContent;
  if (props.network && props.network === "linkedin") {
    postContent = (
      <LinkedInEmbed
        url={`https://www.linkedin.com/embed/feed/update/${media.postId}`}
        width={"100%"}
        height={"100%"}
      />
    );
  } else if (props.network && props.network === "twitter") {
    postContent = (
      <div className="tweet-box">
        <Tweet tweetId={media.id_str} />
      </div>
    );
  } else {
    postContent = (
      <div className="post-ig">
        <div className="wrapper-1 wrapper-6">
          <div className="post-header">
            <div
              className="avatar"
              style={{
                backgroundImage: `url(
                           ${
                             media.owner &&
                             media.owner[0] &&
                             media.owner[0].avatar
                           }
                        )`,
              }}
            ></div>
            <div className="text-and-secondary-text">
              <div className="apple satoshi-bold-eerie-black-14px">
                {props.profile && props.profile[props.network][0].handle}
              </div>
              <div className="date label-2">
                <Moment format="MMM D YYYY">{media.timestamp}</Moment>
              </div>
            </div>
          </div>
          <p className="plant-portraiture label-2">{media.caption}</p>
        </div>
        <div className="img">
          {media.media_type !== "VIDEO" ? (
            <img
              className="d-videoContentAttachment__image"
              src={media.media_url}
              alt=""
            />
          ) : (
            <>
              <video src={media.media_url} />
              <div className="d-videoContentAttachment__duration">
                <span className="sbks-sprite-play-white"></span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <article className={`${props.network} post-wrapper`}>
      {postContent}
      <div class="stat-set gap-3 flew-wrap justify-content-center">
        {stats &&
          stats.map((stat) => (
            <article class="stat-display">
              <div class="x47 body-1">{stat.value ? stat.value : 0}</div>
              <div class="engagememt label-2">{stat.title}</div>
            </article>
          ))}
      </div>
    </article>
  );
};

export default PostCard;
