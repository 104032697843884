import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartsSample = (props) => {
	let data = [];
	if (props.data && props.data.length > 0) {
		data = props.data;
	}
	if (props.max) {
		data = props.data.filter((item, i) => {
			if (i <= props.max && i >= props.min) {
				return item;
			}
			return null;
		});
	}

	let color;
	if (props.network === "facebook") {
		color = "#3b5998";
	} else if (props.network === "instagram") {
		color = "#c32aa3";
	} else if (props.network === "youtube") {
		color = "#ff0000";
	} else if (props.network === "twitter") {
		color = "#1da1f2";
	} else if (props.network === "tiktok") {
		color = "#010101";
	} else if (props.network === "linkedin") {
		color = "#287bbc";
	}

	const state = {
		series: [
			{
				name: props.title,
				data: data.map((item) => item.value),
			},
		],
		options: {
			chart: {
				type: "line",
				height: 350,
				toolbar: {
					show: true,
				},
				zoom: {
					enabled: true,
				},
				events: {
					zoomed: function (chartContext: any, { xaxis, yaxis }) {
						props.setMin(xaxis.min - 1);
						props.setMax(xaxis.max - 1);
					},
					scrolled: function (chartContext, { xaxis }) {
						props.setMin(xaxis.min - 1);
						props.setMax(xaxis.max - 1);
					},
				},
			},

			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: "bottom",
							offsetX: -10,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					dataLabels: {
						total: {
							enabled: true,
							style: {
								fontSize: "13px",
								fontWeight: 900,
							},
						},
					},
				},
			},
			xaxis: {
				categories: data.map((item) => item.date),
			},
			fill: {
				opacity: 1,
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return props.network === "linkedin" &&
							props.title === "Linkedin Engagement"
							? value + "%"
							: value;
					},
				},
			},
			dataLabels: {
				enabled: true,
				textAnchor: "start",
				formatter: function (value, opt) {
					return props.network === "linkedin" &&
						props.title === "Linkedin Engagement"
						? value + "%"
						: value;
				},
				offsetX: 0,
			},

			colors: [color],
		},
	};
	return (
		<ReactApexChart
			options={state.options}
			series={state.series}
			type="line"
			height={340}
		/>
	);
};

export default ApexChartsSample;
