import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import qs from "query-string";
import ClientBox from "../../components/ClientBox";
import { get, makeUrlParams } from "../../helpers/functions";

const ClientList = (props) => {
  const fields = useSelector((state) => state);
  const location = useLocation();
  const history = useNavigate();
  const [myClients, setMyClients] = useState([]);
  const clientID = localStorage.getItem("signingInClient");

  const fetchData = useCallback(async () => {
    if (fields.user && fields.user.role === "admin") {
      const token = localStorage.getItem("token");
      const myClientsData = await get(`api/get-clients`, token);
      console.log(JSON.parse(myClientsData.body).data);
      setMyClients(JSON.parse(myClientsData.body).data);
    }
  }, [fields.user]);

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (
      query.oauth_token ||
      query.state ||
      query.auth_code ||
      query.scope ||
      query.code
    ) {
      history({
        pathname: `/dashboard/${clientID}`,
        search: `?${makeUrlParams(query)}`,
      });
    } else if (fields.user && fields.user.id) {
      fetchData();
    }
  }, [
    fetchData,
    fields.user,
    fields.currentModal,
    clientID,
    history,
    location,
  ]);
  return (
    <>
      {myClients &&
        myClients.length > 0 &&
        myClients.map((item) => <ClientBox user={item} />)}
    </>
  );
};

export default ClientList;
