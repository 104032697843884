import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { post } from "../../helpers/functions";
import { setCurrentModal } from "../../redux/action-creators";

export default function Header() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const closeModal = () => {
    dispatch(setCurrentModal(null, null));
  };

  const startAuth = () => {
    setLoading(true);
    post("api/create-client", {
      email: email,
      password: password,
      name: name,
      role: role,
    })
      .then((res) => {
        console.log(res);
        dispatch(setCurrentModal(null, null));
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div
      className="modal add-widget-modal fade show"
      id="kt_modal_create_campaign"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-fullscreen p-9">
        <div className="modal-content modal-rounded">
          <div className="modal-header py-7 d-flex justify-content-between">
            <div className="top-bar">
              <h1 className="title">Add Client</h1>
              <div className="icon-button" onClick={closeModal}>
                <img className="x" src="/assets/img/x.svg" alt="X" />
              </div>
            </div>
          </div>

          <div className="modal-body scroll-y m-5">
            <div className="mx-auto w-100 mw-600px pt-0 pb-10 fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="create_ticket-form card">
                <div className="card-body">
                  <div className="form-row align-items-center">
                    <div className="mb-10 fv-row fv-plugins-icon-container form-group col-xl-12">
                      <label className="required form-label mb-3" htmlFor="">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mb-10 fv-row fv-plugins-icon-container form-group col-xl-12">
                      <label className="required form-label mb-3" htmlFor="">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container form-group col-xl-12">
                      <label className="required form-label mb-3" htmlFor="">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container form-group col-xl-12">
                      <label className="required form-label mb-3" htmlFor="">
                        Role
                      </label>
                      <select
                        className="form-control form-control-lg form-control-solid"
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="user">Client</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>

                    <div className="col-xl-12">
                      <div className="d-flex flex-stack pt-10 text-center justify-content-center">
                        {!loading && (
                          <button
                            className="btn btn-lg btn-primary"
                            onClick={startAuth}
                          >
                            Create
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
