import React, { useState } from "react";
import { post } from "../helpers/functions";
import LoadingWidget from "./DashboardWidgets/LoadingWidget";

const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

const NetworkBox = (props) => {
  const [loading, setLoading] = useState(false);
  const [reAuth, setReAuth] = useState(false);
  const sync = async (network, clientID) => {
    setLoading(true);
    post("api/sync-data", { network, client: clientID }).then((res) => {
      //
      if (res.body.success) {
        props.getUser();
        setLoading(false);
      }
    });
  };

  const { startAuth, user, network, clientID } = props;

  return (
    <article
      className={`card widget-add widget ${
        user && user[network] && user[network].length > 0 ? "linked" : ""
      }`}
    >
      <div className="network-title">{capitalize(network)} </div>
      {!reAuth && user && user[network] && user[network].length > 0 ? (
        <>
          {(user && user[`${network}_synced`] === 0) ||
            (!user[`${network}_synced`] && (
              <div onClick={() => sync(network, clientID)} className="button">
                <div className="log-in label-2">
                  {loading ? <LoadingWidget /> : "Sync Data"}
                </div>
              </div>
            ))}
          {user && user[`${network}_synced`] && (
            <div className="link-btn" onClick={() => setReAuth(true)}>
              Reauthorize
            </div>
          )}
        </>
      ) : (
        <button className="button" onClick={() => startAuth(network, clientID)}>
          Link Account
        </button>
      )}
    </article>
  );
};

export default NetworkBox;
