import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { post } from "../../helpers/functions";
import { setCurrentSideNav } from "../../redux/action-creators";

export default function DashboardAdminMenu() {
  const dispatch = useDispatch();
  const fields = useSelector((state) => state);
  const [active, setActive] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [clientAdmins, setClientAdmins] = useState([]);

  const fetchData = useCallback(async () => {
    const adminList = await post("api/get-admins", { client: fields.client });
    setAdmins(adminList.body.data);
    setClientAdmins(adminList.body.client_admins);
  }, [fields]);

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      setActive(true);
    });
  }, [fetchData]);

  const revokeAccess = (admin) => {
    const data = { client: fields.client, admin: admin.id };
    post("api/revoke-access", data).then((res) => {
      fetchData();
    });
  };

  const grantAccess = (admin) => {
    const data = { client: fields.client, admin: admin.id };
    post("api/grant-access", data).then((res) => {
      fetchData();
    });
  };

  const colors = [
    "#55efc4",
    "#81ecec",
    "#74b9ff",
    "#a29bfe",
    "#fd79a8",
    "#ff7675",
    "#fab1a0",
    "#ffeaa7",
  ];
  const closeModal = () => dispatch(setCurrentSideNav(null));
  return (
    <div className={`side-nav-parent ${active ? "active" : ""}`}>
      <div className="side-nav">
        <div className="inner">
          <button className="close-btn" onClick={closeModal}>
            <img className="x" src="/assets/img/x.svg" alt="X" />
          </button>
          <header className="side-nav-header">
            <h1>Dashboard Access</h1>
          </header>
          <div className="list__list" id="list-items">
            {admins &&
              admins.length > 0 &&
              admins.map((admin, i) => (
                <div key={i} className="list__card">
                  <div className="list__data">
                    <div
                      className="admin-icon list__img"
                      style={{ background: colors[i] }}
                    >
                      {admin.name.charAt(0)}
                    </div>
                    <div>
                      <p className="list__name">{admin.name}</p>
                    </div>
                  </div>

                  <div>
                    {clientAdmins.includes(admin.id) ? (
                      <button
                        className="btn btn-primary short red"
                        onClick={() => revokeAccess(admin)}
                      >
                        Revoke Access
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary short"
                        onClick={() => grantAccess(admin)}
                      >
                        Grant Access
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
