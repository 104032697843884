import React from "react";
import { setCurrentSideNav } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";
import DashboardAdminMenu from "./SideNavs/DashboardAdminMenu";

export default function SideNav() {
  const dispatch = useDispatch();
  const currentSideNav = useSelector((state) => state.currentSideNav);
  const client = useSelector((state) => state.client);
  switch (currentSideNav) {
    case "DashboardAdminMenu":
      return (
        <DashboardAdminMenu
          hideModal={() => dispatch(setCurrentSideNav(null))}
          modal={1}
          client={client}
        />
      );

    default:
      return null;
  }
}
