import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartsSample = (props) => {
	const state = {
	    series: [44, 55, 13, 43, 22],
	    options: {
	      chart: {
	        width: 380,
	        type: 'pie',
	      },
	      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
	      responsive: [{
	        breakpoint: 480,
	        options: {
	          chart: {
	            width: 200
	          },
	          legend: {
	            position: 'bottom'
	          }
	        }
	      }]
	    },
	};
	return (
		<ReactApexChart
			options={state.options}
			series={state.series}
			type="bar"
			height={340}
		/>
	);
};

export default ApexChartsSample;
