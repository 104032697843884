import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "../../redux/action-creators";
import "react-tooltip/dist/react-tooltip.css";
import {
  makeTitle,
  getNetworkFromString,
  getNetworks,
  post,
} from "../../helpers/functions";
import { Line, Bar, Radial } from "./elements";

const MediumWidget = (props) => {
  const network = getNetworkFromString(props.name, getNetworks());
  const dispatch = useDispatch();
  const fields = useSelector((state) => state);
  console.log(fields);
  const [showOptions, setShowOptions] = useState(false);
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const [type, setType] = useState(
    props.widget.type ? props.widget.type : "bar",
  );

  const switchGraph = () => {
    const typeVar = type === "bar" ? "line" : "bar";
    setType(typeVar);
    post("save/save-widget", {
      id: props.widget.id,
      type: typeVar,
      client: fields.client,
    }).then((newLayoutData) => {
      dispatch(setLayout(newLayoutData.body.layouts));
    });
  };

  const saveSvgAsPng = (svgElt, imageName) => {
    const xml = new XMLSerializer().serializeToString(svgElt);
    const svg64 = btoa(xml);
    const b64Start = "data:image/svg+xml;base64,";
    const { width, height } = svgElt.getBoundingClientRect();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width * 3;
    canvas.height = height * 3;
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, width, height);
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, 0, 0, width * 3, height * 3);
      downloadImage(canvas, imageName);
    };
    image.src = b64Start + svg64;
  };

  const downloadImage = (canvas, imageName) => {
    canvas.toBlob((blob) => {
      const anchor = document.createElement("a");
      anchor.download = imageName;
      anchor.href = URL.createObjectURL(blob);
      anchor.click();
      URL.revokeObjectURL(anchor.href);
    }, "image/png");
  };
  const savePNG = (e, name) => {
    console.log(name);
    var $this = document.getElementById(name);
    if ($this.getElementsByTagName("svg")?.length) {
      saveSvgAsPng($this.getElementsByTagName("svg")[0], "test.png");
    }
  };
  return (
    <div
      id={props.name}
      className="card h-100 mb-xl-5 gap-4 widget-desktop-list-with-highlight-md "
    >
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {makeTitle(network)}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {makeTitle(props.name, true, network)}
          </span>
        </h3>

        <div className="card-toolbar">
          <div class="end" onClick={() => setShowOptions(!showOptions)}>
            <img
              class="dots-three"
              src="/assets/img/dotsthree.svg"
              alt="DotsThree"
            />
          </div>
          <div
            className={`top-57 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
              showOptions ? "active" : ""
            }`}
            data-kt-menu="true"
          >
            <div className="menu-item px-5 py-4">
              <div
                className={`d-widgetOptionsFolded__button type-${type}`}
                onClick={switchGraph}
                id={`switch-${props.name}`}
              >
                Toggle Graph
              </div>
            </div>
            <div className="menu-item px-5 py-4">
              <div
                className="d-widgetOptionsFolded__button"
                onClick={(e) => savePNG(e, props.name)}
                name={props.name}
                id={`export-${props.name}`}
              >
                Save as Image
              </div>
            </div>
            <div className="menu-item px-5 py-4">
              <div
                className="d-widgetOptionsFolded__button"
                onClick={() => props.removeWidget(props.name)}
                name={props.name}
                data-tooltip-content="Close"
                id={`close-${props.name}`}
              >
                Remove
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body d-flex flex-column">
        {!props.loading && (
          <div className="d-widget__content">
            <div className="d-widget__chart">
              <div className="d-widget__spacer"></div>

              {type === "bar" && (
                <Bar
                  data={props.data}
                  title={makeTitle(props.name)}
                  max={max}
                  min={min}
                  setMin={setMin}
                  setMax={setMax}
                  network={network}
                />
              )}
              {type === "line" && (
                <Line
                  data={props.data}
                  title={makeTitle(props.name)}
                  max={max}
                  min={min}
                  setMin={setMin}
                  setMax={setMax}
                  network={network}
                />
              )}
              {type === "radial" && (
                <Radial data={props.data} title={makeTitle(props.name)} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediumWidget;
