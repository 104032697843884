import React, { useEffect, useCallback } from "react";
import { get } from "../helpers/functions";
import { useNavigate } from "react-router-dom";
import { setActiveUser } from "../redux/action-creators";
import { useDispatch } from "react-redux";

export default function Homepage(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const getUser = useCallback(async () => {
    const userResult = await get(
      `auth/get-user`,
      localStorage.getItem("token"),
    );
    if (JSON.parse(userResult.body)) {
      dispatch(setActiveUser(JSON.parse(userResult.body).data));
      window.location.href = "/home";
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && token.length > 0) {
      getUser();
    } else {
      history("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  return (
    <div className="landing screen">
      <div className="hero-content">
        <div className="badge-1 badge-4">
          <img
            className="icon-rosewood"
            src="/assets/img/icon---rosewood.svg"
            alt="rw-logo"
          />
          <div className="by-rosewood-creative">BY ROSEWOOD CREATIVE</div>
        </div>
        <div className="wrapper-1">
          <h1 className="title">Improved insights for social campaigns.</h1>
          <p className="radar-helps-you-trac">
            Radar helps you track and manage lorem your social data to power
            dolor decision making, ipsum set.
          </p>
        </div>
        <div className="button">
          <a href="/login">
            <div className="log-in">Log in</div>
          </a>
        </div>
        <p className="dont-have-an-account-sign-up satoshi-regular-normal-fuscous-gray-14px">
          <span className="span0 satoshi-regular-normal-fuscous-gray-14px">
            Don’t have an account?{" "}
          </span>
          <span className="span1 satoshi-regular-normal-fuscous-gray-14px">
            Sign up
          </span>
        </p>
      </div>
      <div className="images">
        <img src="/assets/img/images.png" alt="user" />
      </div>
    </div>
  );
}
