import React, { useEffect, useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "./helpers/functions";
import Home from "./Views/Home";
import Login from "./Views/Login";
import Homepage from "./Views/Homepage";
import Register from "./Views/Register";
import AuthPage from "./Views/AuthPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Terms from "./Views/Terms";
import Privacy from "./Views/Privacy";
import Dashboard from "./Views/Dashboard";
import PostDetail from "./Views/PostDetail";
import Modal from "./components/Modal";
import SideNav from "./components/SideNav";
import { setActiveUser } from "./redux/action-creators";

export default function App(props) {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const currentSideNav = useSelector((state) => state.currentSideNav);

  const getUser = useCallback(async () => {
    const userResult = await get(
      `auth/get-user`,
      localStorage.getItem("token"),
    );
    if (JSON.parse(userResult.body)) {
      dispatch(setActiveUser(JSON.parse(userResult.body).data));
    }
  }, [dispatch]);

  const getUserMatch = useCallback(
    async (token) => {
      const userMatch = await post("api/get-user-auto-login", { token });
      if (userMatch.body && userMatch.body.user) {
        localStorage.setItem("token", userMatch.body.tokens.access.token);
        dispatch(setActiveUser(userMatch.body.user));
        setTimeout(() => {
          window.location.href = "/home";
        }, 500);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (token) {
      getUser();
    }

    console.log("user_token");
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (query.user_token) {
      console.log(query.user_token);
      getUserMatch(query.user_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      <div
        id="main-wrapper"
        className={currentSideNav !== null ? "show-sidenav" : ""}
      >
        <Header />
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/user-accounts/:id" element={<Home />} />
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/auth-page" element={<AuthPage />} />
          <Route path="/dashboard/:id" element={<Dashboard />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/detail" element={<PostDetail />} />
        </Routes>
        <Footer />
        <Modal />
        <SideNav />
      </div>
    </div>
  );
}
