import React, { useEffect, useCallback } from "react";
import { get } from "../helpers/functions";
import { useNavigate } from "react-router-dom";
import UserSocialMediaAccounts from "../components/widgets/UserSocialMediaAccounts";
import ClientList from "../components/widgets/ClientList";
import { setActiveUser, setCurrentModal } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";

export default function Home(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");

  const getUser = useCallback(async () => {
    const userResult = await get(
      `auth/get-user`,
      localStorage.getItem("token"),
    );
    if (JSON.parse(userResult.body)) {
      dispatch(setActiveUser(JSON.parse(userResult.body).data));
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && token.length > 0) {
      getUser();
    } else {
      history("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="entry screen">
        <div className="wrapper wrapper-3">
          <div className="wrapper-1 wrapper-3">
            <h1 className="title display">Welcome, Natalie</h1>
            <div className="wrapper-2 wrapper-3">
              <div className="header-1">
                <div className="dashboards">Dashboards</div>
                {user && user.role === "admin" && (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => dispatch(setCurrentModal("CreateUserForm"))}
                  >
                    <img
                      className="plus-circle"
                      src="/assets/img/pluscircle.svg"
                      alt="PlusCircle"
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {user && user.role === "user" && (
                  <UserSocialMediaAccounts user={user} />
                )}
                {user && user.role === "admin" && <ClientList />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
