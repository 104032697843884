import React from "react";
import { useSelector } from "react-redux";
import { makeTitle } from "../helpers/functions";

const MenuItem = (props) => {
  const { widget, loading } = props;
  const fields = useSelector((state) => state);
  const active =
    fields.layout[widget.network] &&
    fields.layout[widget.network].widgets.find((e) => e.i === widget.i);
  const onClick = () => {
    if (active) {
      props.removeWidget(widget.i);
    } else {
      props.addWidget(widget);
    }
  };
  let networkClass = "hide";
  if (
    props.client &&
    props.client[props.widget.network] &&
    props.client[props.widget.network].length > 0
  ) {
    networkClass = "show";
  }
  return (
    <div className={`widget-add widget col-md-2 ${networkClass}`}>
      <div className={`menu-item text-center ${active ? "active" : ""}`}>
        <p className="title">{makeTitle(widget.i, true, widget.network)}</p>
        <p className="network">{makeTitle(widget.network)}</p>
        {/* <img src={`/assets/${widget.network}.svg`} alt="" /> */}
        <button
          className={`btn btn-primary mt-3 ${active ? "remove" : "add"}`}
          onClick={onClick}
        >
          {!loading ? (active ? "Added" : "Add") : "Saving..."}
        </button>
      </div>
    </div>
  );
};

export default MenuItem;
