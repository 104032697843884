import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../redux/action-creators";

const ClientBox = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { user } = props;

  const showWidgetOptions = () => dispatch(setCurrentModal("CreateUserForm"));

  return (
    <>
      {props.create ? (
        <article class="card">
          <div class="header-3"></div>
          <div class="frame-1547764367">
            <div class="text">
              <div class="text-1">
                <div class="headphones label-1">Secondary text</div>
              </div>
              <div class="name-1 name-2">
                <div class="beats header">Apple</div>
              </div>
            </div>
          </div>
          <button className="button" onClick={showWidgetOptions}>
            Create Client
          </button>
        </article>
      ) : (
        <article class="card">
          <div class="header-3"></div>
          <div class="frame-1547764367">
            <div class="text">
              <div class="name-1 name-2">
                <div class="beats header">{user.name}</div>
              </div>
              <div class="text-1">
                <div class="headphones label-1">Secondary text</div>
              </div>
            </div>
          </div>
          <button
            className="button"
            onClick={() => history(`/dashboard/${user.id}`)}
          >
            View
          </button>
        </article>
      )}
    </>
  );
};

export default ClientBox;
