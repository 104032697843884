import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setActiveUser } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showSub, setShowSub] = useState(false);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const logout = () => {
    localStorage.removeItem("token");
    dispatch(setActiveUser(null));
    history("/");
  };
  console.log(location.pathname);

  return (
    <header id="header">
      <Link to={user?.role === "admin" ? "/home" : "/"}>
        <div className="logo">
          <div className="logo">
            <div className="phosphor-radar">
              <div className="overlap-group">
                <img
                  className="ellipse-7"
                  src="/assets/img/ellipse-7-3.svg"
                  alt="Ellipse 7"
                />
                <img
                  className="vector-8"
                  src="/assets/img/vector-8.svg"
                  alt="Vector 8"
                />
                <img
                  className="vector-7"
                  src="/assets/img/vector-7-3.svg"
                  alt="Vector 7"
                />
              </div>
            </div>
          </div>
          <div className="radar courier-regular-normal-eerie-black-20px">
            RADAR
          </div>
        </div>
      </Link>
      <div className="account-nav">
        <div
          className="app-navbar-item ms-1 ms-md-4"
          id="kt_header_user_menu_toggle"
        >
          <div
            onClick={() => setShowSub(!showSub)}
            className="cursor-pointer symbol symbol-35px"
          >
            Account
          </div>

          <div
            className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
              showSub ? "active" : ""
            }`}
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                {/* <div className="symbol symbol-50px me-5">
                  <img alt="Logo" src="/assets/img/profile.svg" />
                </div> */}
                <div className="d-flex flex-column">
                  <div className="fw-bold d-flex align-items-center fs-5">
                    {user?.name}
                    <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                      Pro
                    </span>
                  </div>

                  <div className="fw-semibold text-muted text-hover-primary fs-7">
                    {user?.email}
                  </div>
                </div>
              </div>
            </div>

            <div className="separator my-2"></div>
            <div className="menu-item px-5">
              <div
                role="button"
                tabIndex={-1}
                onClick={logout}
                className="menu-link px-5"
              >
                Sign Out
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
