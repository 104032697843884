import React from "react";
import { setCurrentModal } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";
import WidgetMenu from "./Modals/WidgetMenu";
import CreateUserForm from "./Modals/CreateUserForm";
import FacebookSelect from "./Modals/FacebookSelect";
import LinkedinSelect from "./Modals/LinkedinSelect";

export default function Modal() {
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.currentModal);
  switch (currentModal) {
    case "WidgetMenu":
      return (
        <WidgetMenu
          hideModal={() => dispatch(setCurrentModal(null))}
          modal={1}
        />
      );
    case "FacebookSelect":
      return (
        <FacebookSelect
          hideModal={() => dispatch(setCurrentModal(null))}
          modal={1}
        />
      );
    case "LinkedinSelect":
      return (
        <LinkedinSelect
          hideModal={() => dispatch(setCurrentModal(null))}
          modal={1}
        />
      );
    case "CreateUserForm":
      return (
        <CreateUserForm
          hideModal={() => dispatch(setCurrentModal(null))}
          modal={1}
        />
      );

    default:
      return null;
  }
}
