import React, { useEffect, useState, useCallback } from "react";
import GridLayout from "react-grid-layout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import { useSelector, useDispatch } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import UserSocialMediaAccounts from "../components/widgets/UserSocialMediaAccounts";
import MasterWidget from "../components/widgets/MasterWidget";
import { post, get, makeTitle } from "../helpers/functions";
import EmptyDashboard from "../components/DashboardWidgets/EmptyDashboard";
import LoadingWidget from "../components/DashboardWidgets/LoadingWidget";
import {
  setCurrentModal,
  setLayout,
  setCurrentSideNav,
} from "../redux/action-creators";

export default function Dashboard(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const fields = useSelector((state) => state);
  const [showChannel, setShowChannel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSection, setShowSection] = useState("all");
  const [currentClient, setCurrentClient] = useState(null);
  const [userPlatforms, setUserPlatforms] = useState([]);
  const [setup, setSetup] = useState(false);
  const token = localStorage.getItem("token");
  const [showOptions, setShowOptions] = useState(false);
  const client = Object.keys(params).length !== 0 ? params.id : fields.user.id;

  const getLayout = useCallback(async (networks) => {
    const data = await get(
      `widgets/get-user-layout/${
        Object.keys(params).length !== 0 ? params.id : fields.user.id
      }?networks=${networks.join(",")}`,
      token,
    );
    dispatch(setLayout(JSON.parse(data.body).layouts));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = useCallback(async () => {
    if (fields.user && fields.user.role === "admin") {
      const myClientsData = await get(`api/get-clients`, token);

      const clientList = JSON.parse(myClientsData.body).data.map(
        (client) => client,
      );
      setCurrentClient(clientList.find((client) => client.id === params.id));
      const networks = getUsersPlatforms(
        clientList.find((client) => client.id === params.id),
      );
      getLayout(networks);
      if (Object.keys(params).length === 0) {
        history(`/dashboard/${clientList[0].value}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.user, token]);

  // Mount the Event Listener on loading the site
  useEffect(() => {
    if (Object.keys(params).length !== 0 || (fields.user && fields.user.id)) {
      fetchData();
    }
    if (
      Object.keys(params).length !== 0 &&
      fields.user &&
      fields.user.id &&
      fields.user.id !== params.id
    ) {
      localStorage.setItem("signingInClient", params.id);
    }
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (
      query.oauth_token ||
      query.state ||
      query.auth_code ||
      query.scope ||
      query.code ||
      query.setup
    ) {
      setSetup(true);
    }
  }, [fields.user, history, fetchData, location.search, params]);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  const onLayoutChange = (newLayout, oldItem, newItem, network) => {
    const layout = fields.layout[network].widgets;
    newLayout.forEach((item, i) => {
      const layoutMatchIndex = layout.findIndex((x) => x.i === item.i);
      newLayout[layoutMatchIndex].type = layout[layoutMatchIndex].type;
    });
    const data = {
      client: Object.keys(params).length !== 0 ? params.id : fields.user.id,
      layout: newLayout,
      network,
    };
    post("save/save-layout", data).then((newLayoutData) => {
      dispatch(setLayout(newLayoutData.body.layouts));
    });
  };
  const showWidgetOptions = (network) =>
    dispatch(
      setCurrentModal(
        "WidgetMenu",
        Object.keys(params).length !== 0 ? params.id : fields.user.id,
        null,
        null,
        network,
      ),
    );

  const getUsersPlatforms = (client) => {
    const platforms = [
      "youtube",
      "tiktok",
      "instagram",
      "facebook",
      "twitter",
      "linkedin",
    ];
    const userPlatforms = [];
    platforms.forEach((platform) => {
      if (client && client[platform] && client[platform].length > 0) {
        userPlatforms.push(platform);
      }
    });
    setUserPlatforms(userPlatforms);
    return userPlatforms;
  };

  const boxes = (fields) => {
    return userPlatforms.map((network) => (
      <div
        id=""
        className={`widget-parent d-flex w-100 flex-column ${
          showSection === "all" || showSection === network ? "" : "d-none"
        }`}
      >
        <div className="section-title">
          {makeTitle(network)}
          {fields?.user && fields?.user.role === "admin" && (
            <div
              role="button"
              tabIndex={-1}
              className="add-button d-flex justify-content-end p-0"
              onClick={() => showWidgetOptions(network)}
            >
              <img
                className="plus-circle"
                src="/assets/img/pluscircle.svg"
                alt="PlusCircle"
              />
            </div>
          )}
        </div>
        {console.log(fields)}
        {fields.layout[network] ? (
          <GridLayout
            className=""
            layout={fields?.layout[network]}
            id="kt_content"
            cols={12}
            rowHeight={35}
            width={1150}
            draggableHandle=".MyDragHandleClassName"
            onResizeStop={(newLayout, oldItem, newItem) =>
              onLayoutChange(newLayout, oldItem, newItem, network)
            }
            onDragStop={(newLayout, oldItem, newItem) =>
              onLayoutChange(newLayout, oldItem, newItem, network)
            }
          >
            {fields?.layout[network].widgets.map((widget, i) => {
              if (widget.h === 12) {
                widget.minH = 12;
                widget.maxH = 12;
              } else if (widget.h === 6) {
                widget.minH = 6;
                widget.maxH = 6;
              }

              return (
                <div
                  className={`MyDragHandleClassName ${widget.network}-${widget.i}`}
                  key={widget.i}
                  data-grid={widget}
                >
                  <MasterWidget
                    type={widget.i}
                    key={widget.i}
                    widget={widget}
                    client={client}
                    user={fields.user}
                  />
                </div>
              );
            })}
          </GridLayout>
        ) : (
          <EmptyDashboard network={network} />
        )}
      </div>
    ));
  };
  return (
    <div className="radar-home">
      <div className="content-area">
        <div className="dash-header">
          <div className="client-info w-100">
            {/* <div className="avatar-1"></div> */}
            <div className="text-and-secondary-text">
              <h1 className="title display">
                {makeTitle(currentClient?.name)}
              </h1>
            </div>
            {!setup ? (
              <div className="ms-auto">
                <div
                  className="end"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <img
                    className="dots-three"
                    src="/assets/img/dotsthree.svg"
                    alt="DotsThree"
                  />
                </div>
                <div
                  className={`top-57 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                    showOptions ? "active" : ""
                  }`}
                  data-kt-menu="true"
                >
                  <div className="menu-item px-5 py-4">
                    <div
                      role="button"
                      tabIndex={-1}
                      className=""
                      id="copy-tooltip"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://radar.rosewoodcreative.com/home?user_token=${currentClient.token}`,
                        );
                      }}
                    >
                      Copy Sign In URL
                    </div>
                  </div>
                  {fields.user && fields.user.role === "admin" && (
                    <div className="menu-item px-5 py-4">
                      <div
                        role="button"
                        tabIndex={-1}
                        className="ml-10"
                        onClick={() =>
                          dispatch(
                            setCurrentSideNav("DashboardAdminMenu", params.id),
                          )
                        }
                      >
                        Add Admin
                      </div>
                    </div>
                  )}
                  <div className="menu-item px-5 py-4">
                    {!setup && (
                      <div
                        role="button"
                        tabIndex={-1}
                        className="ml-10"
                        onClick={() => {
                          setSetup(true);
                          setShowOptions(false);
                        }}
                      >
                        Setup
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={-1}
                className="ms-auto ml-10 btn btn-primary secondary-color"
                onClick={() => {
                  setSetup(false);
                  setShowOptions(false);
                }}
              >
                Back To Dashboard
              </div>
            )}
          </div>
          <div className="filters">
            {!setup && (
              <article className="dropdown-labeled-default">
                <div className="time-period satoshi-bold-eerie-black-14px">
                  Channel
                </div>
                <div
                  className="drop"
                  onClick={() => setShowChannel(!showChannel)}
                >
                  <div className="input">
                    <div className="icon-contained-icon-1">
                      {showSection !== "all" && (
                        <img
                          className="icon"
                          src={`/assets/img/icon---${showSection}.svg`}
                          alt="icon / instagram"
                        />
                      )}
                    </div>
                    <div className="this-month header">
                      {showSection !== "all" ? makeTitle(showSection) : "All"}
                    </div>
                  </div>
                  <img
                    className="icon-chevron-down"
                    src="/assets/img/icon---chevron-down.svg"
                    alt="icon / chevron down"
                  />
                </div>
                <div
                  className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                    showChannel ? "active" : ""
                  }`}
                  data-kt-menu="true"
                >
                  {showSection !== "all" && (
                    <div className="menu-item px-3 py-4">
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                          setShowSection("all");
                          setShowChannel(false);
                        }}
                        className="menu-link px-5"
                      >
                        Show All
                      </div>
                    </div>
                  )}
                  {userPlatforms?.map((network) => (
                    <div className="menu-item px-3 py-4">
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                          setShowSection(network);
                          setShowChannel(false);
                        }}
                        className="menu-link px-5"
                      >
                        <div className="icon-contained-icon-1 me-4">
                          <img
                            className="icon"
                            src={`/assets/img/icon---${network}.svg`}
                            alt="icon / instagram"
                          />
                        </div>
                        {makeTitle(network)}
                      </div>
                    </div>
                  ))}
                </div>
              </article>
            )}
            {/* <article className="dropdown-labeled-default">
              <div className="time-period satoshi-bold-eerie-black-14px">
                Time Period
              </div>
              <div className="drop">
                <div className="input">
                  <div className="this-month-1 header">
                    This Quarter
                  </div>
                </div>
                <img
                  className="icon-chevron-down-1"
                  src="/assets/img/icon---chevron-down.svg"
                  alt="icon / chevron down"
                />
              </div>
            </article> */}
          </div>
        </div>
        {!loading ? (
          setup ? (
            <div className="entry mt-50 bg-white p-0">
              <div className="wrapper-2 wrapper-3">
                <div className="row">
                  <UserSocialMediaAccounts user={fields.user} />
                </div>
              </div>
            </div>
          ) : (
            boxes(fields)
          )
        ) : (
          <LoadingWidget />
        )}
      </div>
    </div>
  );
}
