import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { post, get } from "../../helpers/functions";
import { setCurrentModal, setLayout } from "../../redux/action-creators";
import MenuItem from "../MenuItem";
import widgetListData from "../widgetListData.json";

export default function Header() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [client, setClient] = useState(null);

  const fields = useSelector((state) => state);

  const getUser = useCallback(async () => {
    const userResult = await get(
      `auth/get-user/${fields.client}`,
      localStorage.getItem("token"),
    );
    if (JSON.parse(userResult.body)) {
      setClient(JSON.parse(userResult.body).data);

      getUsersPlatforms(JSON.parse(userResult.body).data);
    }
  }, [fields]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  const removeWidget = (name) => {
    setLoading(name);
    const layout = fields.layout;
    const itemIndex = layout.map((item) => item.i).indexOf(name);
    layout.splice(itemIndex, 1);
    const data = {
      client: fields.client,
      layout: layout,
      network: fields.network,
    };
    post("save/save-layout", data).then((newLayoutData) => {
      dispatch(setLayout(newLayoutData.body.layouts));
      setLoading("");
    });
  };

  const addWidget = (widget) => {
    setLoading(widget.i);
    if (!fields.layout[widget.network]) {
      fields.layout[widget.network] = {};
      fields.layout[widget.network].widgets = [];
    }
    let layout = fields.layout[widget.network].widgets;
    for (let i = 0; i < layout.length; i++) {
      layout[i].y += widget.h;
    }
    widget.x = 0;
    widget.y = 0;

    layout.unshift(widget);
    const data = {
      client: fields.client,
      layout: layout,
      network: fields.network,
    };
    post("save/save-layout", data).then((newLayoutData) => {
      console.log(newLayoutData);
      dispatch(setLayout(newLayoutData.body.layouts));
      setLoading("");
    });
  };
  const closeModal = () => {
    dispatch(setCurrentModal(null, null));
  };

  const getUsersPlatforms = (client) => {
    const platforms = [
      "youtube",
      "tiktok",
      "instagram",
      "facebook",
      "twitter",
      "linkedin",
    ];
    const userPlatforms = [];
    platforms.forEach((platform) => {
      console.log(client);
      if (client && client[platform] && client[platform].length > 0) {
        userPlatforms.push(platform);
      }
    });
  };

  return (
    <div
      className="modal add-widget-modal fade show"
      id="kt_modal_create_campaign"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className=" modal-dialog modal-fullscreen p-9">
        <div className="modal-content modal-rounded">
          <div className="modal-header py-7  d-flex justify-content-between">
            <div className="top-bar">
              <h1 className="title">Add Widgets</h1>
              <div class="icon-button" onClick={closeModal}>
                <img class="x" src="/assets/img/x.svg" alt="X" />
              </div>
            </div>
          </div>

          <div className="modal-body scroll-y m-5 frame-1547764389 frame p-0">
            {widgetListData
              .filter((w) => w.network === fields.network)
              .map((widget, i) => (
                <MenuItem
                  client={client}
                  key={i}
                  widget={widget}
                  addWidget={addWidget}
                  removeWidget={removeWidget}
                  loading={loading === widget.i}
                  i={i}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
