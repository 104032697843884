import React from "react";

const PostDetail = () => {
  return (
    <div className="content-detall screen">
      <div className="content-area">
        <div className="header-and-filter"></div>
        <div className="content-area-1">
          <div className="flex-col">
            <div className="frame-1547764407 frame-1">
              <img className="frame" src="/assets/img/frame.svg" alt="Frame" />
              <div className="frame-1547764 frame-1">
                <h1 className="title display-mdbold">Shower in the Sun</h1>
                <div className="frame-1547764394 frame-1">
                  <div className="youtube header">Youtube</div>
                  <div className="date label-1">Jan 24, 2024</div>
                </div>
              </div>
            </div>
            <div className="yt">
              <div className="x18-m-views">1.8M views</div>
            </div>
          </div>
          <div className="flex-col-1">
            <div className="dropdown-default">
              <div className="time-period">Time Period</div>
              <div className="drop">
                <div className="input">
                  <div className="this-month header">All time</div>
                </div>
                <img
                  className="icon-chevron-down"
                  src="/assets/img/icon---chevron-down.svg"
                  alt="icon / chevron down"
                />
              </div>
            </div>
            <div className="frame-1547764411 frame-1">
              <div className="frame-1547764 frame-1">
                <div className="impressions text-xlbold">Impressions</div>
                <div className="text-4 display-mdregular">2,220,203</div>
              </div>
              <div className="frame-1547764 frame-1">
                <div className="views text-xlbold">Views</div>
                <div className="text-4 display-mdregular">1,820,118</div>
              </div>
              <div className="frame-1547764 frame-1">
                <div className="unique-views text-xlbold">Unique Views</div>
                <div className="text-4 display-mdregular">309,031</div>
              </div>
              <div className="frame-1547764410 frame-1">
                <div className="frame-1547764 frame-1">
                  <div className="engagement-rate text-xlbold">
                    Engagement Rate
                  </div>
                  <div className="percent display-mdregular">2.5%</div>
                </div>
                <div className="frame-1547764409 frame-1">
                  <div className="frame-1547764-1">
                    <div className="text highlight">301,033</div>
                    <div className="likes text-lgbold">Likes</div>
                  </div>
                  <div className="frame-1547764-1">
                    <div className="text highlight">29,932</div>
                    <div className="comments text-lgbold">Comments</div>
                  </div>
                  <div className="frame-1547764-1">
                    <div className="text highlight">4,092</div>
                    <div className="shares text-lgbold">Shares</div>
                  </div>
                  <div className="frame-1547764-1">
                    <div className="text highlight">203,021</div>
                    <div className="favorites text-lgbold">Favorites</div>
                  </div>
                </div>
              </div>
              <div className="frame-1547764 frame-1">
                <div className="average-watch-time text-xlbold">
                  Average Watch Time
                </div>
                <div className="x35s display-mdregular">35s</div>
              </div>
              <div className="frame-1547764 frame-1">
                <div className="total-view-time text-xlbold">
                  Total View Time
                </div>
                <div className="x302h display-mdregular">302h</div>
              </div>
              <div className="frame-1547764 frame-1">
                <p className="of-followers-vs-no text-xlbold">
                  % of followers vs non-followers who saw the post
                </p>
                <div className="percent-3 display-mdregular">35%</div>
              </div>
              <div className="frame-1547764 frame-1">
                <p className="of-followers-who-commented text-xlbold">
                  % of followers who commented
                </p>
                <div className="percent-3 display-mdregular">72%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
