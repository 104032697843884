import * as Constants from "../constants";

const initialState: State = {
  user: null,
  currentModal: null,
  currentSideNav: null,
  client: null,
  layout: null,
  incominglayout: null,
  incomingData: null,
  network:null
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_ACTIVE_USER:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case Constants.SET_ACTIVE_MODAL:
      return Object.assign({}, state, {
        currentModal: action.payload,
        client: action.client,
        incominglayout: action.layout,
        network:action.network,
        incomingData: action.data,
      });
    case Constants.SET_ACTIVE_SIDE_NAV:
      return Object.assign({}, state, {
        currentSideNav: action.payload,
        client: action.client,
      });
    case Constants.SET_ACTIVE_LAYOUT:
      return Object.assign({}, state, {
        layout: action.payload,
      });
    default:
      return state;
  }
}

export { reducer, initialState };
