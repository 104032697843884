import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MediumWidget, SmallWidget, PostsWidget } from "./";
import { post } from "../../helpers/functions";
import { setLayout } from "../../redux/action-creators";

const components = {
	instagram_profile_number_of_posts: MediumWidget,
	instagram_profile_reach: MediumWidget,
	instagram_profile_impressions: SmallWidget,
	instagram_profile_unfollows: MediumWidget,
	instagram_profile_follows: MediumWidget,
	instagram_profile_net_growth_decay: MediumWidget,
	instagram_profile_top: PostsWidget,
	instagram_profile_bottom: PostsWidget,

	facebook_profile_page_posts_impressions: MediumWidget,
	facebook_profile_page_fans: MediumWidget,
	facebook_profile_page_video_views: MediumWidget,
	facebook_profile_page_views_total: MediumWidget,
	facebook_profile_page_engaged_users: MediumWidget,
	facebook_profile_page_total_actions: MediumWidget,
	facebook_profile_reach: MediumWidget,
	facebook_profile_top: PostsWidget,
	facebook_profile_bottom: PostsWidget,
	// instagram_post_likes: MediumWidget,
	// instagram_post_reach: MediumWidget,
	// instagram_post_impressions: MediumWidget,
	// instagram_post_engagement_rate: MediumWidget,
	// instagram_post_comments: MediumWidget,
	// instagram_post_shares: MediumWidget,
	// instagram_post_saves: MediumWidget,
	// instagram_post_link_clicks: MediumWidget,
	// instagram_post_click_through_rate: MediumWidget,
	// instagram_post_video_metrics_views: MediumWidget,
	// instagram_post_avg_view_duration: MediumWidget,
	// instagram_post_audience_retention: MediumWidget,
	// instagram_post_peak_live_viewers: MediumWidget,
	// instagram_post_avg_completion_rate: MediumWidget,
	// instagram_post_stories_interactions: MediumWidget,
	// instagram_post_stories_replies: MediumWidget,
	// instagram_post_stories_shares: MediumWidget,
	// instagram_post_navigation_forward: MediumWidget,
	// instagram_post_navigation_back: MediumWidget,
	// instagram_post_navigation_exited: MediumWidget,
	// instagram_post_navigation_next_story: MediumWidget,
	youtube_profile_total_number_of_videos_published: SmallWidget,
	youtube_profile_comments: SmallWidget,
	youtube_profile_dislikes: SmallWidget,
	youtube_profile_likes: SmallWidget,
	youtube_profile_shares: SmallWidget,
	youtube_profile_retention_performance: SmallWidget,
	youtube_profile_subscribers_gained: SmallWidget,
	youtube_profile_subscribers_lost: SmallWidget,
	youtube_profile_views: MediumWidget,
	youtube_profile_top: PostsWidget,
	youtube_profile_bottom: PostsWidget,
	// youtube_video_click_through_rate: MediumWidget,
	// youtube_video_average_view_duration: MediumWidget,
	// youtube_video_comments: MediumWidget,
	// youtube_video_dislikes: MediumWidget,
	// youtube_video_estimated_minutes_watched: MediumWidget,
	// youtube_video_likes: MediumWidget,
	// youtube_video_shares: MediumWidget,
	// youtube_video_viewer_percentage: MediumWidget,
	// youtube_video_views: MediumWidget,
	// youtube_video_thumbs_up: MediumWidget,
	// youtube_video_thumbs_down: MediumWidget,
	// youtube_video_retention_performance: MediumWidget,
	twitter_profile_retweet_count: SmallWidget,
	twitter_profile_reply_count: SmallWidget,
	twitter_profile_like_count: SmallWidget,
	twitter_profile_quote_count: SmallWidget,
	twitter_profile_user_profile_clicks: SmallWidget,
	twitter_profile_impression_count: SmallWidget,
	twitter_profile_top: PostsWidget,
	twitter_profile_bottom: PostsWidget,

	tiktok_profile_likes_count: MediumWidget,
	tiktok_profile_shares_count: MediumWidget,
	tiktok_profile_comments_count: MediumWidget,
	tiktok_profile_video_views_count: MediumWidget,
	tiktok_profile_followers_count: MediumWidget,
	tiktok_profile_views_count: MediumWidget,
	tiktok_profile_top: PostsWidget,
	tiktok_profile_bottom: PostsWidget,

	linkedin_profile_comments: MediumWidget,
	linkedin_profile_likes: MediumWidget,
	linkedin_profile_shares: MediumWidget,
	linkedin_profile_clicks: MediumWidget,
	linkedin_profile_unique_impressions: MediumWidget,
	linkedin_profile_engagement: MediumWidget,
	linkedin_profile_impressions: MediumWidget,
	linkedin_profile_bottom: PostsWidget,
	linkedin_profile_top: PostsWidget,
};

const MasterWidget = (props) => {
	const dispatch = useDispatch();
	const fields = useSelector((state) => state);
	const [data, setData] = useState(null);
	const [profile, setProfile] = useState(null);
	const [loading, setLoading] = useState(true);
	const [dateRange, setDateRange] = useState(true);
	const [value, setValue] = useState("");
	const [count, setCount] = useState(0);

	const getData = (value) => {
		post("widgets/get-widget-data", {
			network: props.widget.network,
			widget_name: props.widget.i,
			client: props.client,
			value: value,
			count: props.widget.count ? props.widget.count : 3,
			passedToken: localStorage.getItem("token"),
		}).then((res) => {
			setData(res.body.data);
			setProfile(res.body.account);
			setDateRange(res.body.dateRange);
			setCount(res.body.count);
			setValue(res.body.value);
			setLoading(false);
		});
	};
	useEffect(() => {
		getData(props.widget.value ? props.widget.value : "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// eslint-enable-next-line react-hooks/exhaustive-deps

	const removeWidget = (name) => {
		const layout = fields.layout[props.widget.network].widgets;
		console.log(layout);
		const itemIndex = layout.map((item) => item.i).indexOf(name);
		layout.splice(itemIndex, 1);
		const data = {
			client: props.client,
			layout: layout,
			network: props.widget.network,
		};
		post("save/save-layout", data).then((newLayoutData) => {
			dispatch(setLayout(newLayoutData.body.layouts));
		});
	};

	const DynamicComponent = components[props.type];
	return (
		<DynamicComponent
			account={props.account}
			name={props.widget.i}
			className="HEYY"
			network={props.widget.network}
			data={data}
			widget={props.widget}
			loading={loading}
			user={props.user}
			profile={profile}
			dateRange={dateRange}
			count={count}
			value={value}
			setValue={setValue}
			getData={getData}
			removeWidget={removeWidget}
		/>
	);
};

export default MasterWidget;
