import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useSelector, useDispatch } from "react-redux";
import NetworkBox from "../../components/NetworkBox";
import { get, post, startAuth } from "../../helpers/functions";
import { setCurrentModal } from "../../redux/action-creators";

const UserSocialMediaAccounts = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();
  const location = useLocation();
  const fields = useSelector((state) => state);
  const [user, setUser] = useState(null);
  const [youtubeCode, setYoutubeCode] = useState(null);
  const token = localStorage.getItem("token");
  const clientID =
    Object.keys(params).length !== 0 ? params.id : fields.user.id;

  const getUser = useCallback(async () => {
    const userResult = await get(
      `auth/get-user/${clientID}`,
      localStorage.getItem("token")
    );
    if (JSON.parse(userResult.body)) {
      setUser(JSON.parse(userResult.body).data);
    }
  }, [clientID]);

  const FBAuth = (network) => {
    localStorage.setItem("selectedNetwork", network);
    window.FB.login(
      function (response) {
        console.log(response);
        if (response.status === "connected") {
          post(`instagram/passToken`, {
            response: response,
            client: clientID,
            userID: response.authResponse.userID,
          }).then((res) => {
            if (res.body.content.data && res.body.content.data.length === 1) {
              post(`instagram/passAccount`, {
                account: res.body.content.data[0],
                client: clientID,
                userFacebookUserID: response.authResponse.userID,
              }).then((res) => {
                console.log(res);
                if (localStorage.getItem("signingInClient")) {
                  const clientID = localStorage.getItem("signingInClient");
                  localStorage.removeItem("signingInClient");
                  window.location.href =
                    "/dashboard/" + clientID + "?setup=true";
                } else {
                  window.location.href = "/home";
                }
              });
            } else {
              dispatch(
                setCurrentModal("FacebookSelect", null, null, {
                  accounts: res.body.content.data,
                  client: clientID,
                  userFacebookUserID: response.authResponse.userID,
                })
              );
            }
          });
        }
      },
      {
        scope:
          "public_profile,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,read_insights,",
      }
    );
  };

  useEffect(() => {
    // if Home page is loaded directly
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const selectedNetwork = localStorage.getItem("selectedNetwork");
    if (
      selectedNetwork === "twitter" &&
      query.oauth_token === localStorage.getItem("oauthRequestToken")
    ) {
      // makes call to callback endpoint(on our server) with the needed params
      get(
        `twitter/callback/${localStorage.getItem(
          "oauthRequestToken"
        )}/${localStorage.getItem("oauthRequestTokenSecret")}/${
          query.oauth_verifier
        }/${clientID}`,
        token
      )
        .then((response) => {
          const returnedData = JSON.parse(response.body);
          if (returnedData.oauthAccessToken) {
            //check to see if oauthAccessToken is returned
            //   if returned, check to verify
            get(
              `twitter/verify/${returnedData.oauthAccessToken}/${returnedData.oauthAccessTokenSecret}/${clientID}`,
              token
            ).then((res) => {
              getUser();
              history(location.pathname);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // history("/");
        });
    } else if (
      selectedNetwork === "tiktok" &&
      query.state &&
      query.state === "tiktok" &&
      query.code
    ) {
      console.log("TIKTOK");
      get(`tiktok/callback/${query.code}/${clientID}`, token)
        .then((response) => {
          getUser();
          history(location.pathname);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      selectedNetwork === "linkedin" &&
      query.state &&
      query.state === "linkedin" &&
      query.code
    ) {
      console.log("linkedin");
      get(`linkedin/callback/${query.code}/${clientID}`, token)
        .then((response) => {
          console.log("response");
          console.log(JSON.parse(response.body));
          if (JSON.parse(response.body).content) {
            dispatch(
              setCurrentModal("LinkedinSelect", null, null, {
                accounts: JSON.parse(response.body).content,
                client: clientID,
              })
            );
          } else {
            getUser();
            history(location.pathname);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      (selectedNetwork === "instagram" || selectedNetwork === "facebook") &&
      query.code
    ) {
      // makes call to callback endpoint(on our server) with the needed params
      get(`instagram/callback/${query.code}/${clientID}`, token)
        .then((response) => {
          getUser();
          history(location.pathname);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedNetwork === "youtube" && query.code) {
      console.log(query);
      if (youtubeCode !== query.code) {
        setYoutubeCode(query.code);
        // makes call to callback endpoint(on our server) with the needed params
        post(`youtube/callback`, { code: query.code, client: clientID }, token)
          .then((response) => {
            getUser();
            history(location.pathname);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (query.setup) {
      history(location.pathname);
    } else {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, token, params]);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <NetworkBox
        network="twitter"
        user={user}
        startAuth={startAuth}
        prefix="@"
        clientID={clientID}
        getUser={getUser}
      />
      <NetworkBox
        network="instagram"
        user={user}
        startAuth={FBAuth}
        prefix="@"
        clientID={clientID}
        getUser={getUser}
      />
      <NetworkBox
        network="facebook"
        user={user}
        startAuth={FBAuth}
        clientID={clientID}
        getUser={getUser}
      />
      <NetworkBox
        network="youtube"
        user={user}
        startAuth={startAuth}
        clientID={clientID}
        getUser={getUser}
      />
      <NetworkBox
        network="tiktok"
        user={user}
        startAuth={startAuth}
        clientID={clientID}
        getUser={getUser}
      />
      <NetworkBox
        network="linkedin"
        user={user}
        startAuth={startAuth}
        clientID={clientID}
        getUser={getUser}
      />
    </>
  );
};

export default UserSocialMediaAccounts;
